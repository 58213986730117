<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark w-full text-white w-full fixed md:static"
		>
			<span class="ml-2 font-bold">Agregando productos</span>
			<div class="flex w-1/2 items-center justify-between">
				<div class="flex items-center justify-center">
					<button
						@click="addNewArrow"
						class="font-bold py-2 px-4 inline-flex items-center focus:outline-none"
					>
						<svg
							id="Capa_1"
							enable-background="new 0 0 551.13 551.13"
							height="512"
							viewBox="0 0 551.13 551.13"
							width="512"
							class="h-6 w-6 mr-2 fill-current text-white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
							/>
							<path
								d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
							/>
						</svg>
						<span class="underline">Añadir producto</span>
					</button>
				</div>
				<div class="flex items-center justify-center">
					<router-link to="/Productos"
						><button
							@click="$emit('click')"
							class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
						>
							<svg
								version="1.1"
								id="Capa_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 368.022 368.022"
								style="enable-background:new 0 0 368.022 368.022;"
								class="h-6 w-6"
								xml:space="preserve"
							>
								<path
									style="fill:#D7443E;"
									d="M314.101,54.133c71.79,71.847,71.744,188.287-0.103,260.077s-188.287,71.744-260.077-0.103 c-71.75-71.806-71.75-188.167,0-259.974c71.381-71.847,187.49-72.224,259.337-0.843C313.54,53.57,313.821,53.851,314.101,54.133z"
								/>
								<polygon
									style="fill:#ffffff;"
									points="275.439,124.663 215.88,184.223 275.439,243.782 243.57,275.651 184.011,216.092 124.452,275.651 92.582,243.782 152.142,184.223 92.582,124.663 124.452,92.794 184.011,152.353 243.57,92.794"
								/>
							</svg>
							<span class="underline ml-2">Cancelar</span>
						</button></router-link
					>
				</div>
			</div>
		</div>
		<div class="flex items-center justify-center mt-20 md:mt-0">
			<table class="table-auto">
				<thead>
					<tr>
						<th colspan="2" class="border-2 px-4 py-2 bg-gray-200">
							Agregando Productos
						</th>
					</tr>
					<tr>
						<th class="border-2 px-4 py-2 w-auto">Rubro</th>
						<th class="border-2 px-4 py-2">Producto</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td v-if="mostrarInputRubro" class="border-2 px-4">
							<vSelect
								class="w-40"
								:options="opciones"
								v-model="rubroNuevo"
							></vSelect>
						</td>
						<td v-else class="border-2 px-4 py-2">
							<input
								type="text"
								class="focus:outline-none"
								v-model="rubroNuevo"
							/>
						</td>
						<td class="border-2 px-4 py-2">
							<input
								type="text"
								class="focus:outline-none"
								v-model="productoNuevo"
								v-on:keyup.enter="agregarProducto"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<div>
				<button @click="agregarProducto">
					<svg
						id="Capa_1"
						enable-background="new 0 0 551.13 551.13"
						height="512"
						viewBox="0 0 551.13 551.13"
						width="512"
						class="h-10 w-10 ml-4 fill-current text-gray-600"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
						/>
						<path
							d="m292.788 137.783h-34.446v120.56h-120.56v34.446h120.56v120.56h34.446v-120.56h120.56v-34.446h-120.56z"
						/>
					</svg>
				</button>
			</div>
		</div>
		<div class="flex justify-center pt-4">
			<input type="checkbox" @click="mostrarInputRubro = !mostrarInputRubro" />
			<label class="pl-2" for="vehicle1">¿Agregar Nuevo Rubro?</label>
		</div>
		<div v-if="showMessage" class="flex justify-center pt-3">
			<p>{{ message }}</p>
		</div>

		<div
			v-if="mostrarLista"
			class="flex items-center justify-center pt-10 pr-12 mr-3"
		>
			<table class="table-auto">
				<thead>
					<tr>
						<th colspan="2" class="border-2 px-4 py-2 bg-gray-200">
							Productos Agregados
						</th>
					</tr>
					<tr>
						<th class="border-2 px-4 py-2 w-auto">Rubro</th>
						<th class="border-2 px-4 py-2">Producto</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="producto in Productos" :key="producto">
						<td class="border-2 px-4">
							{{ producto.rubro }}
						</td>
						<td class="border-2 px-4 py-2">
							{{ producto.producto }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import gql from "graphql-tag";

export default {
	components: {
		vSelect
	},
	name: "AgregarProductos",

	data() {
		return {
			rubroNuevo: "",
			productoNuevo: "",
			mostrarInputRubro: true,
			verificado: true,
			showMessage: false,
			mostrarLista: false,
			message: "",
			rubrosTotal: [],
			verificarArray: [],
			productosVerificar: {},
			Productos: []
		};
	},
	apollo: {
		// Query with parameters
		rubrosTotal: {
			query: gql`
				query rubrosTotal($filtro: String!) {
					rubrosTotal(filtro: $filtro) {
						rubro
					}
				}
			`,
			variables: {
				filtro: "rubro"
			},
			fetchPolicy: "cache-and-network",
			update: data => data.rubrosTotal,
			error: (error, vm) => (vm.feedback.errorSearched = true),
			watchLoading(isLoading, countModifier) {
				// isLoading is a boolean
				// countModifier is either 1 or -1
				this.$store.state.isLoading = isLoading;
			}
		}
	},
	computed: {
		opciones: function() {
			let llenarRubro = [];
			for (let i = 0; i < this.rubrosTotal.length; i++) {
				llenarRubro.push(
					this.rubrosTotal[i].rubro.charAt(0).toUpperCase() +
						this.rubrosTotal[i].rubro.slice(1)
				);
			}
			return llenarRubro;
		}
	},

	methods: {
		agregarProducto: function() {
			if (this.rubroNuevo != "" && this.productoNuevo != "") {
				this.$store.state.isLoading = true;
				this.$apollo
					.query({
						query: gql`
							query($producto: String!) {
								productoVerificar(producto: $producto) {
									id
									rubro
								}
							}
						`,
						variables: {
							producto: this.productoNuevo
						},
						fetchPolicy: "no-cache"
					})
					.then(data => {
						this.verificado = true;
						this.showMessage = false;
						for (let i = 0; i < data.data.productoVerificar.length; i++) {
							if (data.data.productoVerificar[i].rubro.toLowerCase()) {
								this.verificado = false;
								this.message = "¡Producto ya existe con id!";
								this.showMessage = true;
							}
						}
						if (this.verificado) {
							this.guardarProducto();
						}
						this.$store.state.isLoading = false;
					})
					.catch(error => {
						this.$store.state.isLoading = false;
						this.message = error;
						this.showMessage = true;
					});
			}
		},

		guardarProducto: function() {
			this.$store.state.isLoading = true;
			this.$apollo
				.mutate({
					mutation: gql`
						mutation($rubro: String!, $producto: String!) {
							crearProducto(rubro: $rubro, producto: $producto) {
								id
							}
						}
					`,
					variables: {
						rubro: this.rubroNuevo.toLowerCase(),
						producto: this.productoNuevo.toLowerCase()
					}
				})
				.then(data => {
					this.$store.state.isLoading = false;
					this.message =
						"Producto con id:" +
						data.data.crearProducto.id +
						" Guardado con Exito.";
					this.showMessage = true;
					this.addNewArrow();
					this.nuevoRubroSelect();
					this.mostrarLista = true;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					this.message = error;
					this.showMessage = true;
				});
		},
		nuevoRubroSelect() {
			let agregarRubroOpciones = true;
			for (let index = 0; index < this.opciones.length; index++) {
				if (this.opciones[index] === this.rubroNuevo.toLowerCase()) {
					agregarRubroOpciones = false;
					index = this.opciones.length;
				}
			}

			if (agregarRubroOpciones) {
				this.opciones.push(this.rubroNuevo);
			}
		},
		addNewArrow() {
			this.Productos.push({
				rubro: this.rubroNuevo,
				producto: this.productoNuevo
			});
		}
	}
};
</script>
